<template>
  <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
    <MobileHeader :noPin="!pinExist"/>
    <div v-if="buttom_disabled">
      <Loader />
    </div>
    <form @submit.prevent="reset()" class="mx-6">
      <div class="mb-4">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="old_password">
          Old password
        </label> -->
        <Input v-model="form.old_password" :placeholder="$t('login.oldPassword')" id="old_password" type="password" required />
        <!-- <input v-model="form.old_password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="old_password" type="password" placeholder="•••••••••••"> -->
      </div>
      <div class="mb-6">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
          Password
        </label> -->
        <Input v-model="form.password" :placeholder="$t('login.newPassword')" type="password" id="password" required />
        <!-- <input v-model="form.password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="•••••••••••"> -->
      </div>
       <div>
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="password2">
          Password again
        </label> -->
        <Input v-model="form.password2" :placeholder="$t('login.confirmPassword')" type="password" id="password2" required />
        <!-- <input v-model="form.password2" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password2" type="password" placeholder="•••••••••••"> -->
      </div>
      <p class="font-body text-bodyS text-twine text-left mb-6">{{$t('password.short')}}</p>
      <div v-if="error" class="mb-6 text-center">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" style="background-color: #ff00008a; font-size: 12px;">
          {{error}}
        </label> -->
        <SystemMessage :text="error" type='error' />
      </div>
      <div v-if="success" class="mb-6 text-center">
        <SystemMessage :text="success" type='success'/>
      </div>
      <div class="width-full text-center">
        <Button buttonType="primary" :text="$t('login.reset')" :disabledBtn="buttom_disabled" type="submit"/>

        <!-- <button :disabled="buttom_disabled" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
          Reset 
        </button> -->
      </div>
    </form>
    <MobileNavigation :pinExist="pinExist"/>
  </div>
</template>
<script>

import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import MobileNavigation from "@/components/mobile/Navigation.vue";
import MobileHeader from "@/components/mobile/Header.vue";
import SystemMessage from '@/components/SystemMessage.vue';
import Loader from '@/components/Loader.vue';

export default {
  components: {
        Input,
        Button,
        MobileNavigation,
        MobileHeader,
        SystemMessage,
        Loader
    },
  data(){
    return {
      baseURL: process.env.VUE_APP_AXIOS_URL,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      form: {
        old_password: '',
        password: '',
        password2: '',
        user_id: '',
      },
      error: '',
      success: '',
      buttom_disabled: false,
      pinExist: false,
    }
  },
  mounted() {
    if (this.baseURL.includes('product.')) {
      this.baseURL = this.baseURL.replace('product.', '');
    }
    this.$i18n.locale = this.lang;
    this.pinCheck();
  },
  methods: {
    async reset() {
        const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
        this.form.user_id = authUser.data.user.id;
        this.error = '';
        this.buttom_disabled = true;
        this.form.old_password = document.querySelector('#old_password').value;
        this.form.password = document.querySelector('#password').value;
        this.form.password2 = document.querySelector('#password2').value;
        this.axios.put(this.baseURL + `/wp-json/wp/v2/password-reset`, this.form, {}).then(response => {
            const response_data = response.data;
            if (response_data.error) {
              if (response_data.error == "The new passwords aren't equals!") {
                this.error = this.$t('password.unequal');
                this.buttom_disabled = false;
              } else if (response_data.error == "The new passwords is to short!") {
                this.error = this.$t('password.short');
                this.buttom_disabled = false;
              } else if (response_data.error == "The old password is not correct!") {
                this.error = this.$t('password.oldincorrect');
                this.buttom_disabled = false;
              }
            } else {
                this.success = this.$t('password.success');
                this.buttom_disabled = false;
                setTimeout(() => {this.$router.go(-1)}, 3000);
            }
        }).catch( (error) => {
            this.error = error;
            this.buttom_disabled = false;
        });
        
    },
    langchanged_(value) {
        this.lang = value;
    },
    pinCheck() {
        try{
            const result = this.$store.dispatch('pinCheck');
            result.then(res => {
                if (res && res != '') {
                    this.pinExist = true;
                } else {
                    this.pinExist = false;
                }
            }).catch(() => {
                this.pinExist = false;
            })
        }catch(e){
            this.pinExist = false;
        }
    },
  }
}
</script>