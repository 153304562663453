<template>
<div class="">
  <div class="logo absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 p-8 rounded-full border-2 z-100">
    <svg class="absolute -top-1 -left-1 transform -rotate-90" height="164" width="164">
        <circle class="loader-svg" cx="82" cy="82" r="79" />
    </svg>
    <img class="" :src="logo()" alt="">
  </div>
  <div class="fixed opacity-1 sticy-header-background top-0 left-0 right-0 bottom-0 z-70 pointer-events-auto"></div>
</div>           
</template>

<script>
export default {
  name: "Loader",
  methods: {
    logo(){
      return require("../assets/Logo-light.png");
    }
  }
}
</script>

<style>
.loader-svg{
  fill: none;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke: #da723c;
  stroke-dasharray: 500;
  stroke-dashoffset: 1000;
  animation: smm-loader 3s linear infinite;
}

@keyframes smm-loader{
  to {
    stroke-dashoffset: 0;
  }
}
</style>